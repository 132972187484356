<template>
  <div class="goods-box">
    <nav-bar
      :isFixed="true"
      :title="$t('商品详情')"
      :bgc="'background-color: #f8f8f8;'"
    ></nav-bar>
    <div class="detail-box" :class="{ 'top-box': !isMobile() }">
      <div class="img-box">
        <img
          :src="getPointsImgUrl(goodsInfo.picturePath) || defaultImage"
          @error="imgOnError($event)"
        />
      </div>
      <div class="info-box">
        <div class="name-box">{{ goodsInfo.name }}</div>
        <div class="points-stock">
          <div>
            <span class="points-num">{{ goodsInfo.productPoints }} {{$t('積分')}}</span>
            <span class="price">HK${{ goodsInfo.price }}</span>
          </div>
          <div class="stock-num">{{$t("庫存")}}：{{ goodsInfo.stock }}</div>
        </div>
      </div>
    </div>
    <div class="page-title">{{ $t("圖文詳情") }}</div>
    <div class="page-content" v-html="goodsInfo.page"></div>
    <div>
      <div
        class="footer-btn"
        v-if="goodsInfo.productPoints > goodsInfo.points && goodsInfo.stock > 0"
      >
        {{ $t('積分不足') }}
      </div>
      <div
        class="footer-btn"
        v-else-if="goodsInfo.stock == 0 || goodsInfo.stock == null"
      >
        {{ $t('庫存不足') }}
      </div>
      <div
        class="footer-btn"
        v-else-if="goodsInfo.purchaseLimit === '1' && goodsInfo.isPay === 1"
      >
        {{ $t("限購1次") }}
      </div>
      <div
        class="footer-btn"
        style="background: #99cc33"
        @click="openShow()"
        v-if="
          goodsInfo.isPay !== 1 &&
          goodsInfo.productPoints <= goodsInfo.points &&
          goodsInfo.stock > 0
        "
      >
        {{ $t("立即兌換") }}
      </div>
    </div>
    <van-popup
      v-model:show="show"
      closeable
      position="bottom"
      class="popup-box"
    >
      <div class="popup-content">
        <div class="img-box">
          <img
            class="img"
            :src="getPointsImgUrl(goodsInfo.picturePath) || defaultImage"
            @error="imgOnError($event)"
            alt=""
          />
        </div>
        <div class="name-points">
          <div class="product-name">{{ goodsInfo.name }}</div>
          <div class="product-point">{{$t('積分')}} {{ goodsInfo.productPoints }}</div>
        </div>
        <div class="exchange-box">
          <div class="exchange-title">{{ $t("兌換數量") }}</div>
          <div>
            <van-stepper
              v-model="amount"
              min="1"
              :max="goodsInfo.stock"
              integer
            />
          </div>
        </div>
        <div class="popup-footer" @click="sure">{{ $t('確認') }}</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { queryDetail } from "@/api/pointsMall";
import { getPointsImgUrl } from "@/utils/util";
import { getToken } from "@/utils/token";
export default {
  data() {
    return {
      goodsInfo: {
        picturePath: "",
        name: "",
        price: 0,
        productPoints: 0,
        stock: 0,
        page: "",
      },
      defaultImage: require("@/assets/image/default.png"),
      show: false,
      amount: 0,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getPointsImgUrl,
    isMobile() {
       const langEnv = this.$route.query.langEnv;
      var u = navigator.userAgent;
      const isAndroid =
        (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
        u.indexOf("MMMMessenger") > -1; //android终端
      const isIos =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
        u.indexOf("MMMMessenger") > -1; //ios终端
      return isAndroid || isIos;
    },
    async getDetail() {
       const langEnv = this.$route.query.langEnv;
      const res = await queryDetail({
        id: this.$route.params.id,
        langEnv: langEnv?langEnv:window.localStorage.getItem("langEnv"),
      },langEnv);
      if (res.resultID === 1200) {
        this.goodsInfo = res.data;
      }
    },
    imgOnError(e) {
      e.target.src = this.defaultImage;
    },
    openShow() {
      this.show = true;
    },
    sure() {
      if (getToken()) {
        if (
          this.amount * this.goodsInfo.productPoints >
          this.goodsInfo.points
        ) {
          this.$toast("積分不足");
          return;
        }
        if (this.amount > this.goodsInfo.stock) {
          this.$toast("庫存不足");
          return;
        }
        this.$router.push({
          path: "/pointsMall/confirm",
          query: {
            pointsProductId: this.goodsInfo.id,
            amount: this.amount,
          },
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.goods-box {
  background: #f3f5f7;
  .top-box {
    padding-top: 44px;
  }
  .detail-box {
    .img-box {
      width: 100%;
      min-height: 320px;
      max-height: 640px;
      border-bottom: 1px solid #e1e1e1;
    }
    .info-box {
      padding: 10px;
      background: #fff;
      border-bottom: 1px solid #e1e1e1;
      .name-box {
        font-size: 16px;
        color: #232326;
        line-height: 20px;
        font-weight: bold;
      }
      .points-stock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .points-num {
          font-size: 14px;
          font-family: "Helvetica";
          color: #f23030;
          margin-right: 8px;
        }
        .price {
          text-decoration: line-through;
          font-size: 14px;
          color: #333333;
        }
        .stock-num {
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
  .page-title {
    line-height: 44px;
    text-align: center;
    color: #333;
    font-size: 16px;
    background: #f8f8f8;
  }
  .page-content {
    padding: 2px 2px 80px 2px;
    background: #fff;
  }
  .footer-btn {
    color: #fff;
    background: #c8c7cc;
    text-align: center;
    line-height: 50px;
    bottom: 0px;
    position: fixed;
    width: 100%;
  }
}
:deep(.popup-box) {
  height: 70vh;
  overflow: visible;
  .popup-content {
    position: relative;
    height: 100%;
    .img-box {
      position: absolute;
      left: 8px;
      top: -26px;
      width: 100px;
      height: 100px;
      border-radius: 3px;
      border: 1px solid #f0f1f3;
      background-color: #fff;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
    }
    .name-points {
      padding: 10px 20px 0px 126px;
      .product-name {
        color: #232326;
        font-size: 14px;
        line-height: 22px;
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -ms-line-clamp: 2;
        -o-line-clamp: 2;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        -o-box-orient: vertical;
        word-break: break-all;
        margin-bottom: 2px;
      }
      .product-point {
        color: #f23030;
        font-size: 14px;
      }
    }
    .exchange-box {
      display: flex;
      align-items: center;
      padding: 30px 10px;
      border-top: 1px solid #dadada;
      margin-top: 30px;
      border-bottom: 1px solid #ededed;
      .exchange-title {
        width: 35%;
        line-height: 40px;
        color: #484848;
        padding: 0 4px 8px 0;
        font-size: 14px;
      }
    }
    .popup-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      background: #99cc33;
      color: #fff;
      text-align: center;
      line-height: 50px;
    }
  }
}
</style>
